import { useParams } from "react-router-dom";
import Frame from "Frame/index.js";
import ReactPlayer from "react-player/file";

import clip from "Media/indoctrination.mp4";

const Home = () => {
  const params = useParams();
  const language = params.language || "en";
  const active = "culture-of-hate";

  return (
    <Frame active={active} language={language}>
      <div className="title is-4 home">
        The blind hatred we see in Hamas didn't just come from nowhere. <br />
        It has been systematically taught for a long time.
        <br />
        Below are recordings from children programs of Hamas and the PA, with
        English subtitles.
        <br />
      </div>
      <div className="box">
        <ReactPlayer url={clip} controls={true} />
      </div>
    </Frame>
  );
};
export default Home;

