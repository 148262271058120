import { useParams } from "react-router-dom";
import Frame from "Frame/index.js";
const active = "ten-points";

const Home = () => {
  let cnt = 0;
  const params = useParams();
  const language = params.language || "en";

  return (
    <Frame active={active} language={language}>
      <Center language={language} />
    </Frame>
  );
};
export default Home;

// ------------------------------
// ------------------------------
const Center = ({ language }) => {
  switch(language) {
    case "de":
      return <German />;
    case "en":
      return <English />;
    default:
      return <English />;
  }
};

const English = () => {
  let cnt = 0;
  return (
    <div>
      <div className="title is-4">Key Points about Gaza</div>
      <Point index={++cnt}>
        <b>Gaza is a Palestinian mini state</b>. There are no settlements or any
        claims to its territory by Israel.
      </Point>
      <Point index={++cnt}>
        If the people of Gaza decided today that they want to live in peace next
        to Israel, <b>the conflict with Gaza would be over tomorrow</b> morning.
      </Point>
      {/*
              <Point index={++cnt}>
        There is <b>no course of action that Israel can take to end the conflict</b> with
        Gaza. Israel can chose between a variety of bad options to defend her
        citizens. It can act to increase or decrease the tension somewhat. 
        That is all that Israel can do.
      </Point>
        */}

      <Point index={++cnt}>
        The option of coexistence has consistently been rejected in favor of the
        intention to <b>destroy the Jewish state</b>. This is the{" "}
        <b>
          <i>only</i>
        </b>{" "}
        reason for the catastrophic situation between Gaza and Israel.
      </Point>

      <Point index={++cnt}>
        All of Israel, the EU, US and the Arab world would shower a peaceful
        Gaza with money and opportunity. It has beautiful beaches and fertile
        land. It could be a thriving, prosperous, independent state for its
        people. <b>The Singapore of the Middle East</b>. This could happen
        tomorrow with the <b>simple decision of the Gazan people to coexist</b>.
        There would be no opposition to this from Israel.
      </Point>

      <Point index={++cnt}>
        Following Israel's complete withdrawal from Gaza, the people of Gaza
        voted Hamas into power. Hamas is clear about its
        goals. It is an <b>openly genocidal terror organization</b> dedicated to
        the destruction of Israel. It cares not about statehood, independence,
        or a better future for its people. Its aim is the annihilation of Israel
        and her citizens, in word as in deed.
      </Point>

      <Point index={++cnt}>
        There is <b>no occupation</b> in Gaza. Israel has no presence in the
        strip, be it civilian, military or administrative.
      </Point>

      <Point index={++cnt}>
        Gaza has <b>two borders</b>: one with Israel and one with Egypt. Both
        countries protect and control their border with the strip.
      </Point>

      <Point index={++cnt}>
        Israel does impose a blockade on Gaza. This{" "}
        <b>
          blockade is not the cause for Palestinian terrorism, but its
          consequence.
        </b>{" "}
        It was put in place{" "}
        <b>
          <i>after</i>
        </b>{" "}
        Gaza declared war to the death, and is designed to prevent weapons and
        fighters from coming into the strip. Food, humanitarian aid have always
        been allowed in, and tens of thousands of workers have been commuting in
        and out of Israel on work permits. The wars of recent years, and in
        particular the October massacre have demonstrated an{" "}
        <b>imperative need for a blockade</b>.
      </Point>

      <Point index={++cnt}>
        Gazans live in brutal poverty in spite of{" "}
        <b>staggering amounts of international aid</b>. It is provided with
        water and electricity by Israel. Instead of providing for its own
        people, Hamas has put <b>all of its resources into military</b> training
        and infrastructure: weapons, tunnels, terrorism, indoctrination.
      </Point>

      <Point index={++cnt}>
        The suffering of the Palestinians in Gaza is{" "}
        <b>real, but self-inflicted.</b> When a country adopts a
        death cult and commits to the genocide of its neighbor, its population
        will pay a heavy price. See Nazi Germany.
      </Point>
    </div>
  );
}

const German = () => {
  let cnt = 0;
  return (
    <div>
      <div className="title is-4">
        {
          //Key Points about Gaza
        }
        Wichtige Punkte zu Gaza
      </div>
      <Point index={++cnt}>
        <b>Gaza ist ein palästinensischer Ministaat</b>. Es gibt keine
        Siedlungen oder anderweitige Ansprüche Israels auf sein Territorium.
      </Point>
      <Point index={++cnt}>
        Wenn die Menschen in Gaza heute entscheiden würden, dass sie in Frieden
        an der Seite Israels leben wollen,{" "}
        <b>wäre der Konflikt mit Gaza morgen früh beendet</b>.{" "}
      </Point>
      {/*
              <Point index={++cnt}>
        There is <b>no course of action that Israel can take to end the conflict</b> with
        Gaza. Israel can chose between a variety of bad options to defend her
        citizens. It can act to increase or decrease the tension somewhat. 
        That is all that Israel can do.
      </Point>
        */}

      <Point index={++cnt}>
        Die Möglichkeit der Koexistenz wurde bisher konsequent abgelehnt. Stattdessen
        hat man sich darauf eingeschworen,{" "}
        <b>den jüdischen Staat zu zerstören.</b> Das ist der{" "}
        <b>
          <i>einzige</i>
        </b>{" "}
        Grund für die katastrophale Lage zwischen Gaza und Israel.
      </Point>

      <Point index={++cnt}>
        Ganz Israel, die EU, die USA und die arabische Welt würden ein
        friedliches Gaza mit Geld und Möglichkeiten überschütten. Gaza hat
        wunderschöne Strände und fruchtbares Land. Es könnte ein blühender,
        wohlhabender und unabhängiger Staat für seine Bevölkerung sein.{" "}
        <b>Das Singapur des Nahen Ostens</b>. Dies könnte über Nacht geschehen,{" "}
        <b>
          wenn sich die Menschen in Gaza entschieden, mit dem Nachbarn friedlich
          nebeneinander zu leben.
        </b>{" "}
        Es gäbe dagegen aus Israel keinerlei Widerstand.
      </Point>

      <Point index={++cnt}>
        Nachdem sich Israel komplett aus Gaza zurückgezogen hatte, wählten die
        Menschen dort Hamas an die Macht. Hamas ist klar in ihren Zielen. Sie
        ist eine <b>offen genozidale Terrororganisation</b>, die sich der
        Zerstörung Israels verschrieben hat. Sie hat kein Interesse an
        Staatlichkeit, Unabhängigkeit oder eine bessere Zukunft für ihr Volk.
        Ihr Ziel ist die Vernichtung Israels und seiner Bürger, in Wort und Tat.
      </Point>

      <Point index={++cnt}>
        Es gibt in Gaza <b>keine Besatzung</b>. Israel hat im Streifen keine
        Präsenz, sei es zivil, militärisch oder administrativ.
      </Point>

      <Point index={++cnt}>
        Gaza hat <b>zwei Grenzen:</b> eine mit Israel und eine mit Ägypten.
        Beide Länder schützen und kontrollieren ihre Grenze zum Streifen.
      </Point>

      <Point index={++cnt}>
        Israel hat eine Blockade gegen Gaza verhängt. Diese Blockade ist nicht
        die Ursache für den palästinensischen Terrorismus, sondern seine Folge.
        Sie wurde verhängt,{" "}
        <b>
          <i>nachdem</i>
        </b>{" "}
        Gaza Israel den Krieg auf Leben und Tod erklärt hat, und soll
        verhindern, dass Waffen und Kämpfer in den Streifen gelangen.
        Lebensmittel und humanitäre Hilfe waren schon immer erlaubt, und
        Zehntausende Arbeiter pendeln mit Arbeitserlaubnis nach Israel und
        wieder heraus. Die Kriege der letzten Jahre und insbesondere das
        Massaker im Oktober haben die{" "}
        <b>zwingende Notwendigkeit dieser Blockade</b> verdeutlicht.
      </Point>

      <Point index={++cnt}>
        Die Menschen im Gazastreifen leben trotz enormer internationaler Hilfe
        in <b>brutaler Armut</b>. Sie werden von Israel mit Wasser und Strom
        versorgt. Anstatt für die eigene Bevölkerung zu sorgen, hat Hamas{" "}
        <b>alle ihre Ressourcen in militärische</b> Ausbildung und Infrastruktur
        gesteckt: Waffen, Tunnel, Terrorismus, Indoktrination.
      </Point>

      <Point index={++cnt}>
        Das Leid der Palästinenser in Gaza ist{" "}
        <b>real, aber mitverschuldet.</b> Wenn ein Land
        einen Todeskult annimmt und sich dem Völkermord an seinem Nachbarn
        verschreibt, zahlt die Bevölkerung unweigerlich einen hohen Preis. In Deutschland weiss man das .
      </Point>
    </div>
  );
};
  



const Point = ({ index, children }) => {
  return (
    <div className="notification">
      <span className="title is-4">{index}.</span>&nbsp;
      <span className="text-point">{children}</span>
    </div>
  );
};
