import React from "react";
import ReactDOM from "react-dom/client";
// -----------------------------------------
import App from "./App";
// -----------------------------------------
import "Styles/bulma.min.css";
import "Fontawesome/css/all.css";
import "Styles/css.css";

// -----------------------------------------
// -----------------------------------------
const Main = () => (
  <App />
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Main />);
