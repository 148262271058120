import { createBrowserRouter, RouterProvider } from "react-router-dom";
// ------------------------------------------------
import TenPoints from "Screens/TenPoints";
import Resilience from "Screens/Resilience";
import CultureOfHate from "Screens/CultureOfHate";
import SevenOctober from "Screens/SevenOctober";
import Indoctrination from "Screens/Indoctrination";

// ------------------------------------------------
const router = createBrowserRouter([
  {
    path: "/",
    element: <SevenOctober />,
  },
  {
    path: "/:language/",
    element: <TenPoints />,
  },
  {
    path: "/:language/ten-points",
    element: <TenPoints />,
  },
  {
    path: "/:language/footage",
    element: <Resilience />,
  },
  {
    path: "/:language/footage/resilience",
    element: <Resilience />,
  },
  {
    path: "/:language/footage/culture-of-hate",
    element: <CultureOfHate />,
  },
  {
    path: "/:language/footage/seven-october",
    element: <SevenOctober />,
  },
  {
    path: "/indoctrination",
    element: <Indoctrination />,
  },
]);


// ------------------------------------------------
// ------------------------------------------------
const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
// ------------------------------------------------