import { useParams } from "react-router-dom";
import Frame from "Frame/index.js";
import TweetCollection from "Components/index.js";

const tweetids = [
  "1711815632440672564",
  "1711828207366807765",
  "1712318867916075315",
  "1712081882362376554",
  "1712869191072522394",
  "1712525206537802113",
  "1710709770217193906",
  "1712090985646203085",
  "1712086569631269344",
  "1712103674145567028",
  "1712153420310646959",
  "1712009162975277439",
];


const Home = () => {
  const active = "resilience";
  const params = useParams();
  const language = params.language || "en";

  return (
    <Frame active={active} language={language}>
      <TweetCollection tweetids={ tweetids }/>
    </Frame>
  );
};
export default Home;

