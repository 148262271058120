import { useParams } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { TwitterTweetEmbed } from "react-twitter-embed";
import Frame from "Frame/index.js";
  
const tweetids = [
  "1711865025726456185",
  "1711609685952885198",
  "1712802269706461190",
  "1711535277481623722",
  "1718382013583892899",
  "1718312466768150902",
  "1713552343436865715",  
  "1712126688753324328",
  "1716832883682546172",
  "1710549532508045442",
  "1711065567229096045",
  "1716772946365104463",
  "1711022339670819109",
  "1712376617467613246",
  "1711860317787987975",
  "1711901493438120191",
];

const Home = () => {
  const params = useParams();
  const language = params.language || "en";
  const active = "culture-of-hate";
  
  return (
    <Frame active={active} language={language}>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
        <Masonry gutter="10px">
          {tweetids.map((tweetid) => {
            return <TwitterTweetEmbed tweetId={tweetid} key={tweetid} />;
          })}
        </Masonry>
      </ResponsiveMasonry>
    </Frame>
  );
};
export default Home;

const Point = ({ index, children }) => {
  return (
    <div className="notification">
      <span className="title is-4">{index}.</span>&nbsp;
      <span className="text-point">{children}</span>
    </div>
  );
};
