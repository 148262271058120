import { useEffect, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { TwitterTweetEmbed } from "react-twitter-embed";
import shuffle from "lodash.shuffle";
import waitbar from "Media/waitbar.gif";

const itemsPerPage = 4;

// ------------------------------------------------------------
// ------------------------------------------------------------
const TweetCollection = ({ tweetids }) => {
  const [loading, setLoading] = useState(true);
  const [tweets] = useState(tweetids);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const doSetPage = (page) => {
    setLoading(true);
    setPage(page);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  // slice array based on page number
  const start = page * itemsPerPage;
  const end = start + itemsPerPage;
  const tweetsOnPage = tweets.slice(start, end);
  const totalPages = Math.ceil(tweets.length / itemsPerPage);

  return (
    <div>
      <div className={`loading ${loading ? "is-loading" : ""}`}>
        <img src={waitbar} />
      </div>
      <Pagination page={page} totalPages={totalPages} setPage={doSetPage} />
      <div className={`main-content ${loading ? "is-loading" : ""}`}>
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry gutter="10px">
            {tweetsOnPage.map((tweetid) => {
              return <TwitterTweetEmbed tweetId={tweetid} key={tweetid} />;
            })}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </div>
  );
};
export default TweetCollection;

// ------------------------------------------------------------
// ------------------------------------------------------------
const Pagination = ({ page, totalPages, setPage }) => {
  return (
    <nav className="pagination" role="navigation" aria-label="pagination">
      <ul className="pagination-list">
        {Array.from(Array(totalPages).keys()).map((_, i) => {
          return (
            <li key={i}>
              <a
                className={`pagination-link ${i === page ? "is-current" : ""}`}
                aria-label={`Goto page ${i + 1}`}
                onClick={() => setPage(i)}
              >
                {i + 1}
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
