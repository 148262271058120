import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

const languages = {
  en: "English",
  de: "Deutsch",
};

const Frame = ({ active, children, language, subtab }) => {
  const [pickLanguage, setPickLanguage] = useState(false);

  const navigate = useNavigate();
  const setLanguage = (language) => {
    setPickLanguage(false);
    const destination =
      active === "home" ? `/${language}/` : `/${language}/${active}`;
    navigate(destination);
  };

  return (
    <div className="box">
      <div className="language-dropdown">
        <div className={`dropdown ${pickLanguage ? "is-active" : ""}`}>
          <div className="dropdown-trigger">
            <button
              className="button"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
              onClick={() => setPickLanguage(!pickLanguage)}
            >
              <span>{languages[language]}</span>
              <span className="icon is-small">
                <i className="fas fa-angle-down" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div className="dropdown-menu" id="dropdown-menu" role="menu">
            <div className="dropdown-content">
              <a onClick={() => setLanguage("en")} className="dropdown-item">
                English
              </a>
              <a onClick={() => setLanguage("de")} className="dropdown-item">
                Deutsch
              </a>
              <hr className="dropdown-divider" />
              <a href="#" className="dropdown-item">
                <div className="tag">
                  <i className="fa-brands fa-twitter" />
                  &nbsp;@jhendrik7
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      { 
      //<Tabs active={active} language={language} />
} 
      {children}
    </div>
  );
};

const Tabs = ({ active, language }) => {
  const tabs = [
    {
      id: "ten-points",
      text: { en: "10 Points", de: "10 Punkte" },
    },
    {
      id: "footage",
      text: { en: "7 October Footage", de: "Filmmaterial 7. Oktober" },
    },
    /*
    {
      id: "resilience",
      text: { en: "Resilience", de: "Zusammenhalt" },
    },
    {
      id: "seven-october",
      text: { en: "7 October", de: "Der 7. Oktober" },
    },
    {
      id: "culture-of-hate",
      text: { en: "Culture of Hate", de: "Hasskultur" },
    },*/
  ];

  return (
    <div className="tabs is-boxed">
      <ul>
        {tabs.map((tab) => {
          const destination =
            tab.id === "home" ? `/${language}/` : `/${language}/${tab.id}`;
          return (
            <li key={tab.id} className={active === tab.id ? "is-active" : ""}>
              <Link to={destination}>{tab.text[language]}</Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Frame;
