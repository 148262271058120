import { useState } from "react";
import { useParams } from "react-router-dom";
import TweetCollection from "Components/index.js";

import Frame from "Frame/index.js";
const tweetids = Array.from(
  new Set([
    "1712009848659783966",
    "1712213582727799149",
    "1712414643568734625",
    "1711721348689436831",
    "1718561380553351246",
    "1718629167221101008",
    "1718591208178512311",
    "1718368664053842289",
    "1717794957115691393",
    "1718676251747971467",
    "1716556478775980403",
    "1712973993307976025",
    "1716352113083621748",
    "1716083480021414078",
    "1712440616439726289",
    "1711678861463707901",
    "1712340149864919440",
    "1713158899635794028",
    "1713146739882942968",
    "1712191068316275052",
    "1712568335206899965",
    "1712153928622887023",
    "1712383567651643817",
    "1712369429718212787",
    "1710847860835484060",
    "1712160295433445556",
    "1712218368172872047",
    "1711700231367114980",
    "1711741097406279810",
    "1710718030085239075",
    "1710743749272158508",
    "1710753229980504160",
    "1712076900514845038",
    "1711529520912605667",
    "1712097083551850888",
    "1711605435411689500",
    "1711690499042463796",
    "1712214365410066890",
    "1711845306323882328",
    "1710657654895317050",
    "1711424877448548443",
    "1712086410222583872",
    "1711916912123191413",
    "1711891911051477472",
    "1711756827556249638",
  ])
);


const SevenOctober = () => {
  const active = "seven-october";
  const [show, setShow] = useState(false);
  const params = useParams();
  const language = params.language || "en";

  return (
    <Frame active={active} language={language}>
      {show ? (
        <TweetCollection tweetids={tweetids} />
      ) : (
        <Center language={language} onProceed={() => setShow(true)} />
      )}
    </Frame>
  );
};
export default SevenOctober;

// ------------------------------
// ------------------------------
const Center = ({ language, onProceed }) => {
  switch(language) {
    case "de":
      return <German onProceed = { onProceed }/>;
    case "en":
      return <English onProceed = { onProceed } />;
    default:
      return <English onProceed = { onProceed } />;
  }
};

const English = ({onProceed}) => {
  return (
    <div className="notification is-danger">
      The following material is highly disturbing. <br />
      <b>
        Under no circumstances should it be seen by children or people prone to
        anxiety or trauma!
      </b>
      <br />
      We are showing this material to bear witness to what happened.
      <br />
      <br />
      <button className="button is-large" onClick={onProceed}>
        Proceed to View
      </button>
    </div>
  );
}

const German = ({ onProceed }) => {
  return (
    <div className="notification is-danger">
      Das folgene Material ist sehr verstörend. <br />
      <b>
        Unter keinen Umständen sollte es von Kindern oder Menschen mit Angstzuständen oder Traumata gesehen werden!
      </b>
      <br />
      Wir zeigen dieses Material, um Zeugnis von dem abzulegen, was passiert ist.
      <br />
      <br />
      <button className="button is-large" onClick={onProceed}>
        Weiter zur Ansicht
      </button>
    </div>
  );
};
